import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthenticationAutomaticSignInProcessor, AuthenticationProvider } from '@mevodo/mv-react-authentication';
import { AppShell } from './lib/ds/ds';
import AuthenticationProfileProvider from './lib/authentication/AuthenticationProfileProvider';
import { SelectTenant } from './welcome/SelectTenant';
import { SignUpTenant } from './welcome/SignUpTenant';
import { ServiceProviderDashboardItem } from './svpDashboards/ServiceProviderDashboardItem';
import { ServiceProviderManagement } from './svp/ServiceProviderManagement';
import { EndCustomerPortalMainShell } from './portal/EndCustomerPortalMainShell';
import { ServiceProviderCustomerManagement } from './svp/ServiceProviderCustomerManagement';
import { ServiceProviderNotifications } from './svp/ServiceProviderNotifications';
import { TenantUserManagement } from './settings/TenantUserManagement';
import { SettingsManagement } from './settings/SettingsManagement';
import { AppDynamicNavigate } from './AppDynamicNavigate';
import { Logout } from './welcome/Logout';
import { EndCustomerPortalDashboard } from './portal/EndCustomerPortalDashboard';
import { ServiceProviderDataIntegrations } from './svp/ServiceProviderDataIntegrations';
import { TenantManagement } from './settings/TenantManagement';
import { ServiceProviderInvoices } from './svp/ServiceProviderInvoices';
import { EndCustomerReportManagement } from './portal/EndCustomerReportManagement';
import { NotificationsProvider } from 'reapop'
import BrandingProvider from './lib/ds/components/BrandingProvider';
import { ServiceProviderReportsManagement } from './svpReports/ServiceProviderReportsManagement';
import { SettingsBillyConnectionManagement } from './settings/SettingsBillyConnectionManagement';
import { ServiceProviderServiceCredentials } from './svp/ServiceProviderServiceCredentials';
import { SettingsServiceConfiguration } from './settings/SettingsServiceConfiguration';
import { ServiceProviderReportsDiscountProfiles } from './svpReports/ServiceProviderReportsDiscountProfiles';
import { NoTenant } from './welcome/NoTenant';
import { ServiceProviderDashboards } from './svpDashboards/ServiceProviderDashboards';
import { ServiceProviderDashboardOverview } from './svpDashboards/ServiceProviderDashboardOverview';
import { ServiceProviderDashboardManage } from './svpDashboards/ServiceProviderDashboardManage';
import { ServiceProviderReports } from './svpReports/ServiceProviderReports';
import { SettingsStatusOverview } from './settings/SettingsStatusOverview';
import { SettingsSubscriptions } from './settings/SettingsSubscriptions';
import { EndCustomerPortalTriggers } from './portal/EndCustomerPortalTriggers';
import { ServiceProviderTriggers } from './svpTriggers/ServiceProviderTriggers';
import { ServiceProviderTriggersManagement } from './svpTriggers/ServiceProviderTriggersManagement';
import { ServiceProviderPad } from './svpPad/ServiceProviderPad';
import { PreSelectTenant } from './welcome/PreSelect';
import { SettingsIdentityProviders } from './settings/SettingsIdentityProviders';
import { CustomerLauncher } from './welcome/CustomerLauncher';
import { ServiceProviderDevelopmentCenter } from './svpDevelopmentCenter/ServiceProviderDevelopmentCenter';
import { ServiceProviderDevelopmentCenterApiViewer } from './svpDevelopmentCenter/ServiceProviderDevelopmentCenterApiViewer';
import { ServiceProviderDevelopmentCenterPowershell } from './svpDevelopmentCenter/ServiceProviderDevelopmentCenterPowershell';
import { TenantRoleManagement } from './settings/TenantRoleManagement';
import { TenantWebHookManagement } from './settings/TenantWebHookManagement';

function App() {
  
  // ensure the development environment is use the staging accounts service
  let authentication_endpoint = undefined;
  if (window.location.hostname === 'localhost') { authentication_endpoint = 'https://apps.staging.mevodo.com/accounts' }

  // render the view 
  return (  
    <BrandingProvider>
      <NotificationsProvider>     
        <AppShell>              
          <AuthenticationProvider>    
            <AuthenticationAutomaticSignInProcessor client_id='mevodo.cloud.service.cloudbilling' scope='all' authentication_endpoint={authentication_endpoint}>        
              <AuthenticationProfileProvider>                             
                <Routes>
                  <Route index element={<Navigate replace to='/welcome/select'  />} />                              
                  <Route path="tenants/:tenantId" element={<AppDynamicNavigate />}>
                    
                    <Route path="svp/" element={<ServiceProviderManagement />}>                                                            
                      <Route path="customers" element={<ServiceProviderCustomerManagement />} />                                                                      
                      <Route path="notifications" element={<ServiceProviderNotifications />} />        
                      <Route path="integrations" element={<ServiceProviderDataIntegrations />} />    
                      <Route path="invoices" element={<ServiceProviderInvoices />} />                          
                      <Route path="servicecredentials" element={<ServiceProviderServiceCredentials />} />                                                                      
                    </Route>                 

                    <Route path="svpdashboards/" element={<ServiceProviderDashboards />}>                
                      <Route path="overview" element={<ServiceProviderDashboardOverview />} />                      
                      <Route path="dashboards/:dashboardId" element={<ServiceProviderDashboardItem />} />       
                      <Route path="manage" element={<ServiceProviderDashboardManage />} />
                    </Route>                 

                    <Route path="svpreports/" element={<ServiceProviderReports />}>                
                      <Route path="reports" element={<ServiceProviderReportsManagement />} />      
                      <Route path="discountprofiles" element={<ServiceProviderReportsDiscountProfiles />} />                                                                                        
                    </Route>

                    <Route path="svptriggers/" element={<ServiceProviderTriggers />}>                
                      <Route path="triggers" element={<ServiceProviderTriggersManagement />} />                            
                    </Route>

                    <Route path="svppad/" element={<ServiceProviderPad />} />                
                    
                    <Route path="settings/" element={<SettingsManagement />}>                
                      <Route path="users" element={<TenantUserManagement />} />        
                      <Route path="roles" element={<TenantRoleManagement />} />
                      <Route path="tenants" element={<TenantManagement />} />
                      <Route path="identityproviders" element={<SettingsIdentityProviders />} />
                      <Route path="webhooks" element={<TenantWebHookManagement />} />
                      <Route path="billy" element={<SettingsBillyConnectionManagement />} />
                      <Route path="services" element={<SettingsServiceConfiguration />} />
                      <Route path="status" element={<SettingsStatusOverview />} />
                      <Route path="subscriptions" element={<SettingsSubscriptions />} />                                            
                    </Route>

                    <Route path="developmentcenter" element={<ServiceProviderDevelopmentCenter />}>
                      <Route path="powershell" element={<ServiceProviderDevelopmentCenterPowershell />} />
                      <Route path=":apiId" element={<ServiceProviderDevelopmentCenterApiViewer />} />
                    </Route>                    
                    
                    <Route path="portal/" element={<EndCustomerPortalMainShell />}>                  
                      <Route path="reports" element={<EndCustomerReportManagement />} />             
                      <Route path="triggers" element={<EndCustomerPortalTriggers />} />                                                 
                      <Route path=":dashboardId" element={<EndCustomerPortalDashboard />} />                                     
                    </Route>
                    
                  </Route> 
                
                  <Route path="welcome/notenant" element={<NoTenant />} />
                  <Route path="welcome/signup" element={<SignUpTenant />} />
                  <Route path="welcome/select" element={<SelectTenant />} />
                  <Route path="welcome/preselect/:tenantId" element={<PreSelectTenant />} />
                  <Route path="welcome/:tenantId/customers/:customerId/launch" element={<CustomerLauncher />} />                          

                  <Route path="logout" element={<Logout />} />
                </Routes>                                                    
              </AuthenticationProfileProvider>
            </AuthenticationAutomaticSignInProcessor>  
          </AuthenticationProvider>           
        </AppShell>  
      </NotificationsProvider>         
    </BrandingProvider>       
  );  
}

export default App;