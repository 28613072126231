import { getCurrentMonthDefinition, getRelativeMonthDefinition } from "../lib/components/Helpers/DateHelpers"
import { ISdkCustomer } from "../lib/sdk/models/ISdkCustomer"
import { ISdkCustomerCustomPropertyProfile } from "../lib/sdk/models/ISdkCustomerCustomPropertyProfile"
import { ISdkCustomerDataFilterPolicy } from "../lib/sdk/models/ISdkCustomerDataFilterPolicy"
import { ISdkCustomerDatasourcePeriodConsumptions, ISdkCustomerDatasourcePeriodConsumptionsItem } from "../lib/sdk/models/ISdkCustomerDatasource"
import { ISdkCustomerDiscountProfile } from "../lib/sdk/models/ISdkCustomerDiscountProfile"
import { ISdkCustomerGroup } from "../lib/sdk/models/ISdkCustomerGroup"
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient"
import { SdkCustomerConfigurationVaultClient } from "../lib/sdk/SdkCustomerConfigurationVaultClient"
import { SdkCustomerCustomPropertyProfileClient } from "../lib/sdk/SdkCustomerCustomPropertyProfileClient"
import { SdkCustomerDashboardClient } from "../lib/sdk/SdkCustomerDashboardClient"
import { SdkCustomerDataFilterPolicyClient } from "../lib/sdk/SdkCustomerDataFilterPolicyClient"
import { SdkCustomerDatasourceClient } from "../lib/sdk/SdkCustomerDatasourceClient"
import { SdkCustomerDiscountProfileClient } from "../lib/sdk/SdkCustomerDiscountProfileClient"
import { SdkCustomerGroupClient } from "../lib/sdk/SdkCustomerGroupClient"
import { SdkCustomerGroupPartnerChannelClient } from "../lib/sdk/SdkCustomerGroupPartnerChannelClient"

export interface ICustomerManagementItem {
    
    id: string
    name: string
    parentId?: string 

    consumptionRP: number,   
    lastPeriodConsumptionRP: number,

    consumptionPG: number,   
    lastPeriodConsumptionPG: number,
    tenantId: string,
    type: string
    subType?: string

    editable: boolean

    extraIcon?: string   
    
    currency: string

    properties?: { [key: string]: string }
}

export class ServiceProviderCustomerManagementUIService 
{    
    private _customerClient: SdkCustomerClient
    private _customerGroupClient: SdkCustomerGroupClient    
    private _customerGroupPartnerChannelClient: SdkCustomerGroupPartnerChannelClient
    private _customerDiscountProfileClient: SdkCustomerDiscountProfileClient
    private _customerDataFilterPolicyClient: SdkCustomerDataFilterPolicyClient
    private _customerPropertyProfileClient: SdkCustomerCustomPropertyProfileClient
    
    constructor(
        private _tenantId: string,
        private _token: string
    ) {
        this._customerClient = new SdkCustomerClient(_tenantId, _token)
        this._customerGroupClient = new SdkCustomerGroupClient(_tenantId, _token)
        this._customerGroupPartnerChannelClient = new SdkCustomerGroupPartnerChannelClient(_tenantId, _token)
        this._customerDiscountProfileClient = new SdkCustomerDiscountProfileClient(_tenantId, _token)
        this._customerPropertyProfileClient = new SdkCustomerCustomPropertyProfileClient(_tenantId, _token)
        this._customerDataFilterPolicyClient = new SdkCustomerDataFilterPolicyClient(_tenantId, _token)
    }
    
    private BuildCustomerManagementItem(parentId: string, id: string, name: string, type: string, periodConsumptions?: ISdkCustomerDatasourcePeriodConsumptions, editable?: boolean, extraIcon?: string): ICustomerManagementItem {

        let consumption: ISdkCustomerDatasourcePeriodConsumptionsItem = { reported: 0, payGo: 0 };
        let lastMonthConsumption: ISdkCustomerDatasourcePeriodConsumptionsItem  = { reported: 0, payGo: 0 };

        const monthDefinition = getCurrentMonthDefinition()

        if (periodConsumptions && periodConsumptions[monthDefinition]) {
            consumption = periodConsumptions[monthDefinition]
        }

        const lastMonthDefinition = getRelativeMonthDefinition(-1);
        if (periodConsumptions && periodConsumptions[lastMonthDefinition]) {
            lastMonthConsumption = periodConsumptions[lastMonthDefinition]
        }

        return {
            id: id,
            name: name,
            parentId: parentId,
        
            consumptionRP: consumption.reported,
            consumptionPG: consumption.payGo,   
            lastPeriodConsumptionRP: lastMonthConsumption.reported,
            lastPeriodConsumptionPG: lastMonthConsumption.payGo,

            tenantId: this._tenantId,
        
            type: type,

            editable: editable ? editable : false,

            extraIcon: extraIcon,

            currency: ''
        }
    }

    private BuildCustomerManagementItemForCustomer(customer: ISdkCustomer): ICustomerManagementItem {

        let consumption: ISdkCustomerDatasourcePeriodConsumptionsItem = { reported: 0, payGo: 0 };
        let lastMonthConsumption: ISdkCustomerDatasourcePeriodConsumptionsItem  = { reported: 0, payGo: 0 };

        const monthDefinition = getCurrentMonthDefinition()
        if (customer.periodConsumption && customer.periodConsumption[monthDefinition]) {
            consumption = customer.periodConsumption[monthDefinition]
        }

        const lastMonthDefinition = getRelativeMonthDefinition(-1);
        if (customer.periodConsumption && customer.periodConsumption[lastMonthDefinition]) {
            lastMonthConsumption = customer.periodConsumption[lastMonthDefinition]
        }
        
        return { 
            type: 'customer', 
            id: customer.id, 
            name: customer.name, 
            parentId: customer.parentId, 
            consumptionRP: consumption.reported,
            consumptionPG: consumption.payGo,   
            lastPeriodConsumptionRP: lastMonthConsumption.reported,
            lastPeriodConsumptionPG: lastMonthConsumption.payGo,            
            tenantId: customer.tenantId, 
            editable: true,
            currency: customer.primaryCurrency
        }            
    }

    private BuildCustomerManagementItemForDiscountProfile(discountProfile: ISdkCustomerDiscountProfile, tenantId: string, parentId: string): ICustomerManagementItem {

        return { 
            type: 'discountProfile', 
            id: discountProfile.id,
            name: discountProfile.name + ' (' + discountProfile.profileType + ')', 
            parentId: parentId, 
            consumptionRP: 0, 
            consumptionPG: 0, 
            lastPeriodConsumptionRP: 0,
            lastPeriodConsumptionPG: 0,
            tenantId: tenantId, 
            editable: true,
            currency: ''
        }            
    }

    private BuildCustomerManagementItemForDataFilterPolicy(dataFilterPolicy: ISdkCustomerDataFilterPolicy, tenantId: string, parentId: string): ICustomerManagementItem {

        return { 
            type: 'dataFilterPolicy', 
            id: dataFilterPolicy.id,
            name: dataFilterPolicy.name, 
            parentId: parentId, 
            consumptionRP: 0, 
            consumptionPG: 0, 
            lastPeriodConsumptionRP: 0,
            lastPeriodConsumptionPG: 0,
            tenantId: tenantId, 
            editable: true,
            currency: '',
            extraIcon: 'faFilterCircleDollar'
        }            
    }

    private BuildCustomerManagementItemForCustomPropertyProfile(customPropertyProfile: ISdkCustomerCustomPropertyProfile, tenantId: string, parentId: string): ICustomerManagementItem {

        return { 
            type: 'customPropertyProfile', 
            id: customPropertyProfile.id,
            name: customPropertyProfile.name, 
            parentId: parentId, 
            consumptionRP: 0, 
            consumptionPG: 0, 
            lastPeriodConsumptionRP: 0,
            lastPeriodConsumptionPG: 0,
            tenantId: tenantId, 
            editable: true,
            currency: '' 
        }            
    }

    private BuildCustomerManagementItemForCustomerGroup(customerGroup: ISdkCustomerGroup): ICustomerManagementItem {

        return { 
            type: 'group', 
            id: customerGroup.id, 
            name: customerGroup.name, 
            parentId: customerGroup.parentId, 
            consumptionRP: 0, 
            lastPeriodConsumptionRP: 0, 
            consumptionPG: 0, 
            lastPeriodConsumptionPG: 0, 
            tenantId: this._tenantId, 
            editable: false, 
            extraIcon: customerGroup.properties && customerGroup.properties['delayedOperation:move'] ? 'faLock' : customerGroup.groupType === 'partnerchannel' ? 'faHandShake' : undefined, 
            subType: customerGroup.groupType, 
            currency: '', 
            properties: customerGroup.properties}            
    }

    public async loadItems(parentId: string, type: string): Promise<ICustomerManagementItem[]> {

        if (type === 'customer') {

            // load the datasources 
            const datasourceClient = new SdkCustomerDatasourceClient(this._tenantId, parentId, this._token)
            const datasources = await (await datasourceClient.listDatasources()).map(d => this.BuildCustomerManagementItem(parentId, d.id, d.name, 'datasource', d.periodConsumption, !d.managed, d.type.indexOf('giftcard') === 0 ? 'faGiftCard' : undefined ))

            // load the discount profiles 
            const discountProfiles: ICustomerManagementItem[] = (await this._customerDiscountProfileClient.listDiscountProfiles(parentId, 'Customer')).map<ICustomerManagementItem>(item => {
                return this.BuildCustomerManagementItemForDiscountProfile(item, this._tenantId, parentId)
            })

            // load the custom property profiles 
            const customPropertyProfiles: ICustomerManagementItem[] = (await this._customerPropertyProfileClient.listCustomPropertyProfiles(parentId, 'Customer')).map<ICustomerManagementItem>(item => {
                return this.BuildCustomerManagementItemForCustomPropertyProfile(item, this._tenantId, parentId)
            })

            // load the data filter policies
            const dataFilterPolicies: ICustomerManagementItem[] = (await this._customerDataFilterPolicyClient.listDataFilterPolicies(parentId, 'Customer')).map<ICustomerManagementItem>(item => {
                return this.BuildCustomerManagementItemForDataFilterPolicy(item, this._tenantId, parentId)
            })

            // load the dashboards
            const dashboardsClient = new SdkCustomerDashboardClient(this._tenantId, parentId, 'customer', this._token)
            const dashboards = await (await dashboardsClient.listDashboardDefinitions()).map(x => this.BuildCustomerManagementItem(parentId, x.id, x.name, 'dashboard', undefined, false))

            // load the vaults
            const vaultClient = new SdkCustomerConfigurationVaultClient(this._tenantId, parentId, this._token)
            const vaults = await (await vaultClient.listVaults()).map(x => this.BuildCustomerManagementItem(parentId, x.id, x.name, 'vault', undefined, true))


            // done 
            return [...datasources, ...discountProfiles, ...dataFilterPolicies, ...customPropertyProfiles, ...dashboards, ...vaults]            
        } else {
            // load the groups
            const groups: ICustomerManagementItem[] = (await this._customerGroupClient.listCustomerGroups(parentId)).map<ICustomerManagementItem>(item => {
                return this.BuildCustomerManagementItemForCustomerGroup(item)
            })

            // load the customers 
            const customers: ICustomerManagementItem[] = (await this._customerClient.listCustomers(parentId)).map<ICustomerManagementItem>(item => {
                return this.BuildCustomerManagementItemForCustomer(item)            
            })

            // load the discount profiles 
            const discountProfiles: ICustomerManagementItem[] = (await this._customerDiscountProfileClient.listDiscountProfiles(parentId, 'CustomerGroup')).map<ICustomerManagementItem>(item => {
                return this.BuildCustomerManagementItemForDiscountProfile(item, this._tenantId, parentId)
            })

            // load the custom property profiles 
            const customPropertyProfiles: ICustomerManagementItem[] = (await this._customerPropertyProfileClient.listCustomPropertyProfiles(parentId, 'CustomerGroup')).map<ICustomerManagementItem>(item => {
                return this.BuildCustomerManagementItemForCustomPropertyProfile(item, this._tenantId, parentId)
            })

            // load the data filter policies
            const dataFilterPolicies: ICustomerManagementItem[] = (await this._customerDataFilterPolicyClient.listDataFilterPolicies(parentId, 'CustomerGroup')).map<ICustomerManagementItem>(item => {
                return this.BuildCustomerManagementItemForDataFilterPolicy(item, this._tenantId, parentId)
            })

            // load the dashboards
            const dashboardsClient = new SdkCustomerDashboardClient(this._tenantId, parentId, 'CustomerGroup', this._token)
            const dashboards = await (await dashboardsClient.listDashboardDefinitions()).map(x => this.BuildCustomerManagementItem(parentId, x.id, x.name, 'dashboard', undefined, false))
                
            // merge all in one array
            return [...groups, ...customers, ...discountProfiles, ...dataFilterPolicies, ...customPropertyProfiles, ...dashboards]
        }
    }

    public async loadGroupItem(itemId: string, type: string): Promise<ICustomerManagementItem> {
        
        if (type === 'customer') {
            var loadedCustomer = await this._customerClient.getCustomer(itemId)            
            return this.BuildCustomerManagementItemForCustomer(loadedCustomer)                        
        } else if (itemId === 'root') {
            return { type: 'group', id: 'root', name: 'Customers', parentId: undefined, consumptionRP: 0, lastPeriodConsumptionRP: 0, consumptionPG: 0, lastPeriodConsumptionPG: 0, tenantId: this._tenantId, editable: false, subType: 'standard', currency: ''}        
        } else {                
            var loadedGroup = await this._customerGroupClient.getCustomerGroup(itemId)            
            return { type: 'group', id: loadedGroup.id, name: loadedGroup.name, parentId: loadedGroup.parentId, consumptionRP: 0, lastPeriodConsumptionRP: 0, consumptionPG: 0, lastPeriodConsumptionPG: 0, tenantId: this._tenantId, editable: false, extraIcon: loadedGroup.groupType === 'partnerchannel' ? 'faHandShake' : undefined, subType: loadedGroup.groupType, currency: ''}        
        }
    }

    public async loadPartnerChannelItem(groupId: string): Promise<ICustomerManagementItem | undefined> { 
        
        // lookup the cache
        var cachedPartnerChannel = sessionStorage.getItem('mv.partnerchannel.' + groupId)
        if (cachedPartnerChannel) 
        {
            const cachedPartnerChannelObject = JSON.parse(cachedPartnerChannel)
            if (cachedPartnerChannelObject === false) {
                return undefined
            } else {
                return { type: 'group', id: cachedPartnerChannelObject.id, name: cachedPartnerChannelObject.name, parentId: cachedPartnerChannelObject.parentId, consumptionRP: 0, lastPeriodConsumptionRP: 0, consumptionPG: 0, lastPeriodConsumptionPG: 0, tenantId: this._tenantId, editable: false, extraIcon: 'faHandShake', currency: '' }
            }            
        }

        // load the partner channel
        var partnerChannel = await this._customerGroupPartnerChannelClient.getPartnerChannelGroup(groupId)

        // add the result to the cache
        if (partnerChannel) {
            sessionStorage.setItem('mv.partnerchannel.' + groupId, JSON.stringify(partnerChannel))
        } else {
            sessionStorage.setItem('mv.partnerchannel.' + groupId, JSON.stringify(false))
        }

        // reflected the result
        if (!partnerChannel) { return undefined }
        return { type: 'group', id: partnerChannel.id, name: partnerChannel.name, parentId: partnerChannel.parentId, consumptionRP: 0, lastPeriodConsumptionRP: 0, consumptionPG: 0, lastPeriodConsumptionPG: 0, tenantId: this._tenantId, editable: false, extraIcon: 'faHandShake', currency: '' }        
    }

    public async deleteItems(items: ICustomerManagementItem[]): Promise<void> {
        for(let item of items) {
            switch(item.type) {
                case 'group':            
                    await this._customerGroupClient.deleteCustomerGroup(item.id)
                    break;
                case 'customer':
                    await this._customerClient.deleteCustomer(item.id)
                    break;
                case 'datasource':
                    const datasourceClient = new SdkCustomerDatasourceClient(this._tenantId, item.parentId as string, this._token)
                    await datasourceClient.deleteDatasource(item.id);
                    break
                case 'dashboard':
                    const dashboardClient = new SdkCustomerDashboardClient(this._tenantId, item.parentId as string, '', this._token)
                    await dashboardClient.deleteDashboard(item.id);
                    break   
                case 'discountProfile':
                    await this._customerDiscountProfileClient.deleteDiscountProfile(item.id)
                    break;
                case 'customPropertyProfile':
                    await this._customerPropertyProfileClient.deleteCustomPropertyProfile(item.id)
                    break;
                case 'vault': 
                    const vaultClient = new SdkCustomerConfigurationVaultClient(this._tenantId, item.parentId as string, this._token)
                    await vaultClient.deleteVault(item.id);
                    break                    
                case 'dataFilterPolicy': 
                    const dataFilterPolicyClient = new SdkCustomerDataFilterPolicyClient(this._tenantId, this._token)
                    await dataFilterPolicyClient.deleteDataFilterPolicy(item.id);
                    break                    
            }            
        }                     
    }

    public sortItemsByField(items: ICustomerManagementItem[], fieldName: string, descending: boolean): ICustomerManagementItem[] {                
        return items.slice(0).sort((a: any, b: any) => {
            let compareResult = (descending ? a[fieldName] < b[fieldName] : a[fieldName] > b[fieldName]) ? 1 : -1            
            return compareResult
        });                
    }

    public sortItemsByOperation(items: ICustomerManagementItem[], descending: boolean, operation: (item: ICustomerManagementItem) => any): ICustomerManagementItem[] {                
        return items.slice(0).sort((a: any, b: any) => {
            const aValue = operation(a)
            const bValue = operation(b)
            let compareResult = (descending ? aValue < bValue : aValue > bValue) ? 1 : -1            
            return compareResult
        });                
    }

    public async openInCustomerContext(item: ICustomerManagementItem, location: string): Promise<void> {
        await this.openInCustomerContextExt(item.tenantId, item.id, location)
    }

    public async openInCustomerContextExt(customerTenantId: string, customerId: string, location: string): Promise<void> {
        await this.openInCustomerContextExt2(customerTenantId, customerId, location, true)
    }

    public async openInCustomerContextExt2(customerTenantId: string, customerId: string, location: string, newTab: boolean): Promise<void> {        
         
        // issue a token for this customer             
        const token = await this._customerClient.issueToken(customerId);

        // store this token in the local storage 
        sessionStorage.setItem('mv.token.' +  customerTenantId, token);

        // calculte the open uri 
        var lauchUri = process.env.PUBLIC_URL + '/tenants/' + customerTenantId + (location ? location : '')

        if (newTab) {
            // open the new page (Rework the end user portal in a way that it can use the context token)
            window.open(lauchUri,'_blank')
        } else {
            window.location.href = lauchUri
        }
    }

    public async openCustomerPortal(customer: ICustomerManagementItem): Promise<void> {         
        await this.openInCustomerContext(customer, '/portal')        
    }

    public async openCustomerDashboard(customer: ICustomerManagementItem, dashboard: ICustomerManagementItem, allowToEdit: boolean): Promise<void> {                 
        await this.openInCustomerContext(customer, '/portal/' + dashboard.id + (allowToEdit ? '?allowManagedEdit=true' : ''))                
    }

    public async openPartnerPortal(group: ICustomerManagementItem): Promise<void> {  
                
        // issue a token for this customer             
        const token = await this._customerGroupPartnerChannelClient.issueToken(group.id);

        // load the group 
        const loadedGroup = await this._customerGroupClient.getCustomerGroup(group.id)

        // get the tenantid
        const groupTenantId = loadedGroup.properties['PartnerChannelTenantId']

        // store this token in the local storage 
        sessionStorage.setItem('mv.token.' +  groupTenantId, token);

        // open the new page (Rework the end user portal in a way that it can use the context token)
        window.open(process.env.PUBLIC_URL + '/tenants/' + groupTenantId + '/svp/customers', '_blank')
    }    
}