import { IDropdownOption, PanelType } from "@fluentui/react"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useForm } from "react-hook-form"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { getMonthDefinition, getRelativeMonthDefinition } from "../lib/components/Helpers/DateHelpers"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { ControlledDropdown } from "../lib/forms/ControlledDropdown"
import { ICustomerManagementItem } from "./ServiceProviderCustomerManagementUIService"
import { SdkCustomerDatasourceClient } from "../lib/sdk/SdkCustomerDatasourceClient"

export interface ISyncDatasourceAggregatePeriodSelectorPanelProps extends IAppWindowBaseProps { 
    tenantId: string
    parentId: string    
    selectedItems: ICustomerManagementItem[]
}
export const SyncDatasourceAggregatePeriodSelectorPanel = (props: ISyncDatasourceAggregatePeriodSelectorPanelProps) => {

    const auth = useAuthInContext(props.tenantId as string)

    type SelectedPeriod = {
        period: string,
        forcePrevious: boolean,
        cleanUpData: boolean
    }

    const { handleSubmit, control, reset, formState } = useForm<SelectedPeriod>({
        defaultValues: {
            period: getMonthDefinition(new Date())            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
    
    const onDismissDialog = () => {            

        reset()

        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSubmit = (): Promise<void> => {    
        
        const submitHandler = handleSubmit((data: SelectedPeriod) => {                                            
            const sdkClient = new SdkCustomerDatasourceClient(props.tenantId as string, props.parentId, auth.currentToken as string);
            const promisses = props.selectedItems.map((item) => (item as ICustomerManagementItem).editable ? sdkClient.aggregateDatasource(item.id, data.period) : null)                    
            return Promise.all(promisses)            
        })

        return submitHandler()        
    }

    const availablePeriods: IDropdownOption[] = []
    for(let i = -60; i <= 0; i++) {

        const monthString = getRelativeMonthDefinition(i);

        availablePeriods.push({
            key: monthString,
            text: monthString
        })
    }

    availablePeriods.reverse()
    
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Select a period for data aggregation'}
            onDismiss={onDismissDialog}
            onSubmit={onSubmit}
            type={PanelType.medium} 
            progessMessage={'Trigger operation....'} 
            submitLabel={'Aggregate'} 
            dissmissLabel={'Cancel'} 
            isValid={formState.isValid}>

            <p>In rare cases when cuastomers switch the type of the contract e.g. from Microsoft SCE ot Azure Pay as you Go the data export gets interrupted 
                and generates a new set of data files in the data source location. This job is reaggregating!</p>    

            <ControlledDropdown key={'validUntil'} control={control} name={'period'} rules={{ required: 'a valid value is required'}} options={availablePeriods} />                                                                
        </PanelWithDefaultFooter>            
    )
}