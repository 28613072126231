import { DefaultButton, MessageBar, MessageBarType, Panel, PrimaryButton, ProgressIndicator, Stack } from "@fluentui/react"
import { useBoolean } from "@fluentui/react-hooks"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { ControlledTextField } from "../lib/forms/ControlledTextField"
import { SdkCustomerDatasourceClient } from "../lib/sdk/SdkCustomerDatasourceClient"

export interface IImportCustomerDatasourceDialogProps extends IAppWindowBaseProps {
    tenantId: string
    parent?: string
}

export const ImportCustomerDatasourceDialog = (props: IImportCustomerDatasourceDialogProps) => { 

    const auth = useAuthInContext(props.tenantId);           
    
    // load all the templates
    const [error, setError] = useState<string>()
    const [isProcessing, { setTrue: startProccesing, setFalse: stopProcessing}] = useBoolean(false)

    type ImportDataSourceForm = {
        url: string,
        clientId: string,
        secret: string                
    };

    const { handleSubmit, control, reset } = useForm<ImportDataSourceForm>();

    const dissmissDialog = () => {        
        reset()
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSubmit: SubmitHandler<ImportDataSourceForm> = async (data, e) => {        

        try {
            startProccesing()
            setError('')

            // parse the service URL
            const serviceUrl = new URL(data.url)
            
            // create the auth uri 
            const authUri = serviceUrl.origin + '/cb1/sts/issue/oauth/token'        

            // exchange clientId and secret for a token
            // curl -X POST -d "client_id={{YOUR CLIENT ID}}&client_secret={{YOUR CLIENT SECERT}}&grant_type=client_credentials" https://apps.mevodo.com/cb1/sts/issue/oauth/token
            const authData = new URLSearchParams();
            authData.append('client_id', data.clientId);
            authData.append('client_secret', data.secret);
            authData.append('grant_type', 'client_credentials');

            console.log("Generating token... ")
            const tokenResponse = await fetch(authUri, { method: 'post', body: authData })
            if (!tokenResponse.ok)
                throw new Error('Error while fetching token: ' + tokenResponse.statusText)

            console.log("Extracting token from response... ")
            const generatedToken = await tokenResponse.json();
            if (generatedToken === undefined || generatedToken.access_token === undefined)
                throw new Error('Error while extracting token')
            
            // get all datasources 
            console.log("Receiving all containers... ")
            const getDataSourcesUri = serviceUrl.origin + serviceUrl.pathname.replace('/app2/teams', '/api/v2/csps').replace('/apps/accounts', '/accounts')
            const receviedContainers = await fetch(getDataSourcesUri, { headers: { 'Authorization': 'Bearer ' + generatedToken.access_token } })
            if (!receviedContainers.ok)
                throw new Error('Error while fetching datasources: ' + receviedContainers.statusText)
            
            const extractedContainers = await receviedContainers.json()
            if (!extractedContainers || !Array.isArray(extractedContainers))
                throw new Error('Error while extracting datasources')

            // create the datasources
            console.log('Received #' + extractedContainers.length + ' containers')
            console.log("Creating missing datasources in target... ")
                        
            const datasourceClient = new SdkCustomerDatasourceClient(props.tenantId, props.parent as string, auth.currentToken as string)  
            const existingDataSources = await datasourceClient.listDatasources()

            // loop over every extractred container
            for (const container of extractedContainers) {            
                                
                console.log('Creating container ' + (container as any).accountName)

                // check if container still exists 
                const existingContianer = existingDataSources.find(c => c.name === (container as any).accountName)
                if (existingContianer) {
                    console.log('Container ' + (container as any).accountName + ' already exists')
                    return;
                }

                // create the datasource 
                await datasourceClient.createDatasource({
                    name: (container as any).accountName,
                    type:"azure.costmgnt.container",
                    comment:"Imported",
                    fieldValues: {
                        accountName: (container as any).accountName,
                        accountKey: (container as any).accountKey,
                        reportContainer: (container as any).reportContainer,
                        reportFolder: (container as any).reportFolder,
                        skipVerification: 'true'
                    },
                    optionalFieldValues: {}
                });
            }
        
            stopProcessing()            
            dissmissDialog()
        } catch (error) {
            console.log(error)
            setError('Error while importing datasource')
            stopProcessing()
        }
    }
    
    const stackTokens = { childrenGap: 15 };

    const buttonStyles = { root: { marginRight: 8 } };
        
    const onRenderFooterContent = useCallback(() => (        
        <div>
          
          { isProcessing ? (<ProgressIndicator label="Importing datasource..." />) : (<></>)}
          <PrimaryButton onClick={handleSubmit(onSubmit)} styles={buttonStyles} disabled={isProcessing}>Import</PrimaryButton>
          <DefaultButton onClick={dissmissDialog} disabled={isProcessing}>Cancel</DefaultButton>
        </div>
      ),
      // eslint-disable-next-line 
      [isProcessing])

    return (
        <Panel
            isOpen={props.isVisible}
            headerText={'Import Datasources'}
            onDismiss={dissmissDialog}            
            onRenderFooterContent={onRenderFooterContent}            
            isFooterAtBottom={true}>

            { error ? (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        {'Error: ' + error}
                    </MessageBar>
                ) : (<></>)}

            <Stack tokens={stackTokens}>   
                <Stack.Item>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    
                        <ControlledTextField label={'Service Url'} control={control} name={'url'} rules={{ required: 'a valid url is needed'}} disabled={isProcessing} />                                                                     

                        <ControlledTextField label={'ClientId'} control={control} name={'clientId'} disabled={isProcessing} />                                                                     
                        <ControlledTextField label={'Secret'} control={control} name={'secret'} disabled={isProcessing} />                                                                     
                        
                    </form>
                </Stack.Item>                                                      
            </Stack>                                 
  </Panel>
    )
}