import { UseFormWatch } from "react-hook-form"
import { ISdkObjectTemplate, ISdkObjectTemplateField } from "../sdk/models/ISdkObjectTemplate"
import { useEffect, useState } from "react"

export interface ISdkDependentOnWatcherControlProps {
    template: ISdkObjectTemplate
    optionalFields?: ISdkObjectTemplateField[]
    watch: UseFormWatch<any>
    children: (isControlDisabledByDependency: (templateField: ISdkObjectTemplateField) => boolean) => JSX.Element
}

export const SdkDependentOnWatcherControl = (props: ISdkDependentOnWatcherControlProps) => {

    // establish the property watcher to manage dependent on   
    const [controlDisableState, setControlDisableState] = useState<{[key: string]: any}>({})     
    const [optionalPropertyDisableState, setOptionalPropertyDisableState] = useState<{[key: string]: any}>({})     
    const propertyWatcher: string[] = props.watch(props.template.fields.map(f => f.id))  
    const optionalFieldsWatcher: string[] = props.optionalFields ? props.watch(props.optionalFields.map(f => f.id)) : []

    useEffect(() => {
        let newControlDisableState: any = {}        

        propertyWatcher.forEach((value: any, index: number) => {
            newControlDisableState[props.template.fields[index].id] = value
        })
        
        setControlDisableState(newControlDisableState)
    // eslint-disable-next-line        
    }, propertyWatcher)   

    useEffect(() => {
        let newControlDisableState: any = {}        

        optionalFieldsWatcher.forEach((value: any, index: number) => {

            const optionalField = props.optionalFields ? props.optionalFields[index] : undefined
            if (!optionalField) { return }
            newControlDisableState[optionalField.id] = value                
        })        

        setOptionalPropertyDisableState(newControlDisableState)
    // eslint-disable-next-line        
    }, optionalFieldsWatcher)   
    
    const isControlDisabledByDependency = (templateField: ISdkObjectTemplateField) => {

        if (!templateField.dependentOn) { return false; }
    
        const currentValue = controlDisableState[templateField.dependentOn]
        if (currentValue) {
            return false        
        } else if (optionalPropertyDisableState[templateField.dependentOn]) {
            return false
        } else {
            return true
        }                
    }

    return props.children(isControlDisabledByDependency)
}