import { useParams } from "react-router-dom"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { IColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { ISdkWebHookRegistration } from "../lib/sdk/models/ISdkWebHookRegistration"
import { SdkWebhookClient } from "../lib/sdk/SdkWebhookClient"
import { AddOrEditWebhookRegistration } from "./AddOrEditWebhookRegistration"
import { useBoolean } from "@fluentui/react-hooks"
import { useState } from "react"

export interface ITenantWebHookManagementProps {
}

export const TenantWebHookManagement = (props: ITenantWebHookManagementProps) => {
    
    // get the current tenant state
    const { tenantId } = useParams()             

    // get the authenticaiton 
    const auth = useAuthInContext(tenantId as string)

    const [isAddOpen, { setTrue: showAdd, setFalse: hideAdd}] = useBoolean(false)    
    const [isRefreshRequest, { toggle: toggleRefreshRequest}] = useBoolean(false)        
    const [itemtoEdit, setItemToEdit] = useState<ISdkWebHookRegistration>()    
    
    const onClickColumn = (item: ISdkWebHookRegistration) => {
        setItemToEdit(item)
        showAdd()
    }

    const buildColumns = (builder: IColumnsBuilder<ISdkWebHookRegistration>): void  => 
    {        
        builder.IconColumn({name: "Type", headerIcon: 'faWebhook', iconName: 'faWebhook', onClick: onClickColumn})
        builder.TextColumn({name: "Name", value: "name", maxWidth: 250, onClick: onClickColumn})
        builder.TextColumn({name: "Id", value: "id", maxWidth: 250, onClick: onClickColumn})
    }

    const reloadData = async (): Promise<ISdkWebHookRegistration[]> => {  
        const client = new SdkWebhookClient(tenantId as string, auth.currentToken as string)
        return await client.list()        
    }

    const onDeleteItems = (items: ISdkWebHookRegistration[]): Promise<void> => {        
        const client = new SdkWebhookClient(tenantId as string, auth.currentToken as string)                            
        const itemsToDeletePromisses = items.map((item) => client.delete(item.id))
        return Promise.all(itemsToDeletePromisses)
            .then(() => Promise.resolve())
            .catch(() => Promise.reject())
    }

    const addWebHook = (): Promise<void> => {
        showAdd()
        return Promise.resolve()
    }

    return (
        <div className="mv-content-container">

            <PageHeader title={'Webhooks - Manage integrations'}>            
                <span>
                    WebHooks allow to notify other integrated applications regarding important events. It is possibel to configure a web hook for every relevant 
                    event. The web hook will be called with a JSON payload containing the event details.
                </span>
            </PageHeader>                
        
            <NavigationalDetailsListWithStandardOperations<ISdkWebHookRegistration> 
                uniqueIdentifier='webhookroot' 
                onBuildColumns={buildColumns} 
                onLoadData={reloadData} 
                onDeleteItems={onDeleteItems} 
                onNewItem={addWebHook} 
                newItemLabel='Register Webhook'                
                refresh={isRefreshRequest} 
            />
            <AddOrEditWebhookRegistration tenantId={tenantId as string} isVisible={isAddOpen} item={itemtoEdit} dismissDialog={() => { hideAdd(); setItemToEdit(undefined); toggleRefreshRequest()}} />            
        </div>
    ) 
}
