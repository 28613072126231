import { useParams } from "react-router-dom"
import { useState } from "react";
import { INavigationMenuSidebarRouteEntry, NavigationMenuSidebar } from "../lib/components/DetailsListHelper/NavigationMenuSideBar";
import { useSubscriptionContext } from "../lib/authentication/AuthenticationSubscriptionContext";
import { SdkProfileClient } from "../lib/sdk/SdkProfileClient";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook";

export const SettingsManagement = () => {

    const { tenantId } = useParams();            
    const auth = useAuthInContext(tenantId as string)

    const [menuItems, setMenuItems] = useState<INavigationMenuSidebarRouteEntry[] | undefined>(undefined)
    const [initialCalculationDone, setInitialCalculationDone] = useState<boolean>(false)

    const subContext = useSubscriptionContext()
    
    // read all the dashboards the user created for this tenant
    useEffectAsync(async () => {
        
        if (initialCalculationDone) { return }
        setMenuItems(undefined)

        // prepare the access check service
        const accessCheckClient = new SdkProfileClient(auth.currentToken as string);

        // the menuitems 
        const buildedMenuItems: INavigationMenuSidebarRouteEntry[] = []

        // add the manage dashboards item
        buildedMenuItems.push({name: 'Users', route: '/tenants/' + tenantId + '/settings/users'})
        buildedMenuItems.push({name: 'Roles', route: '/tenants/' + tenantId + '/settings/roles'})

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id.endsWith('.serviceprovider'))) {
            buildedMenuItems.push({name: 'Tenants', route: '/tenants/' + tenantId + '/settings/tenants'})
        }
        
        // ask the backend for some permission checks 
        const permissionsToCheck = [
            {id: 'hasEnterpriseSSOPermission', resourceIdentifier: 'mevodo.cloud.service.accounts/' + tenantId + '/tenant', permissionIdentifier: 'mevodo.cloud.service.accounts/idps/list'},
            {id: 'hasWebHookPermission', resourceIdentifier: 'mevodo.cloud.service.cloudbilling/' + tenantId + '/tenant', permissionIdentifier: 'mevodo.cloud.service.cloudbilling/webhooks/list'}
        ]
        
        const permissionCheckResuklt = await accessCheckClient.checkAccess(permissionsToCheck)
                 
        // ask the backend if we have access to this resource
        const hasEnterpriseSSOPermission = permissionCheckResuklt.find((r) => r.id === 'hasEnterpriseSSOPermission')?.allowed
        if (hasEnterpriseSSOPermission) {
            buildedMenuItems.push({name: 'Enterprise SSO', route: '/tenants/' + tenantId + '/settings/identityproviders'})
        }

        // ask the backend if we have access to this resource
        const hasWebHookPermission = permissionCheckResuklt.find((r) => r.id === 'hasWebHookPermission')?.allowed
        if (hasWebHookPermission) {
            buildedMenuItems.push({name: 'Webhooks', route: '/tenants/' + tenantId + '/settings/webhooks'})
        }

        /*if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id.endsWith('.serviceprovider'))) {
            buildedMenuItems.push({name: 'Billy', route: '/tenants/' + tenantId + '/settings/billy'})
        }*/

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id.endsWith('.managedcustomer'))) {
            buildedMenuItems.push({name: 'Services', route: '/tenants/' + tenantId + '/settings/services'})
        }

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id.endsWith('.serviceprovider'))) {
            buildedMenuItems.push({name: 'Status', route: '/tenants/' + tenantId + '/settings/status'})
        }

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => !s.id.endsWith('.managedcustomer'))) {
            buildedMenuItems.push({name: 'Subscriptions', route: '/tenants/' + tenantId + '/settings/subscriptions'})
        }

        setMenuItems(buildedMenuItems)
        setInitialCalculationDone(true)
    }, [tenantId, subContext])

    return (<NavigationMenuSidebar routes={menuItems} />)
}