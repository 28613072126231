import './index.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Icon, initializeIcons, registerIcons } from '@fluentui/react';
import { render } from 'react-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'


const rootElement = document.getElementById('root') as HTMLElement

// add the office items
initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/');

// register the fontawesome icons
registerIcons({
  icons: {    
    // special capabilities for customer tree
    'facgroup': <FontAwesomeIcon icon={regular('folder')} />, 
    'faccustomer': <FontAwesomeIcon icon={regular('buildings')} />, 
    'facdatasource': <FontAwesomeIcon icon={regular('binary')} />, 
    'facdashboard': <FontAwesomeIcon icon={regular('chart-line-up')} />, 
    'facdiscountProfile': <FontAwesomeIcon icon={regular('percent')} />,     
    'faccustomPropertyProfile': <FontAwesomeIcon icon={regular('tags')} />,     
    'facMSFT.PartnerCenter.NewCommerce.SAM': <Icon iconName={'faMicrosoft'} />, 
    'facMSFT.PartnerCenter.Legacy.SAM': <Icon iconName={'faMicrosoft'} />, 
    'facMSFT.SCE.CostMgntExport': <Icon iconName={'faMicrosoft'} />, 
    'facvault': <FontAwesomeIcon icon={regular('vault')} />, 

    // status icons
    'fasinterrupted': <FontAwesomeIcon icon={regular('circle-exclamation')} />, 
    'faspending': <FontAwesomeIcon icon={regular('hourglass-clock')} />,      
    'fasvalid': <FontAwesomeIcon icon={regular('check')} />,
    'fasactive': <FontAwesomeIcon icon={regular('check')} />,
    'fasinactive': <FontAwesomeIcon icon={regular('circle-xmark')} />,
    
    // general icons    
    'faArrowUpRightFromSquare': <FontAwesomeIcon icon={regular('arrow-up-right-from-square')} />,  
    'faArrowUp': <FontAwesomeIcon icon={regular('arrow-up')} />,
    'faArrowDown': <FontAwesomeIcon icon={regular('arrow-down')} />,
    'faArrowProgress': <FontAwesomeIcon icon={regular('arrow-progress')} />,            
    'faAWS': <FontAwesomeIcon icon={brands('aws')} />,    
    'faBell': <FontAwesomeIcon icon={regular('bell')} />,    
    'faBinary': <FontAwesomeIcon icon={regular('binary')} />,
    'faBolt': <FontAwesomeIcon icon={regular('bolt')} />,    
    'faBoxesPacking': <FontAwesomeIcon icon={regular('boxes-packing')} />,        
    'faBrainCircuit': <FontAwesomeIcon icon={regular('brain-circuit')} />,        
    'faBuildings': <FontAwesomeIcon icon={regular('buildings')} />,

    'faCalendar': <FontAwesomeIcon icon={regular('calendar')} />,    
    'faCalendars': <FontAwesomeIcon icon={regular('calendars')} />,    
    'faChartLineUp': <FontAwesomeIcon icon={regular('chart-line-up')} />,   
    'faChartPie': <FontAwesomeIcon icon={regular('chart-pie')} />,   
    'faFileChartColumn': <FontAwesomeIcon icon={regular('file-chart-column')} />,   
    'faCheck': <FontAwesomeIcon icon={regular('check')} />,   
    'faCircleCheck': <FontAwesomeIcon icon={regular('circle-check')} />,          
    'faCircleDownRight': <FontAwesomeIcon icon={regular('circle-down-right')} />,          
    'faCircleExclamation': <FontAwesomeIcon icon={regular('circle-exclamation')} />,   
    'faCircleInfo': <FontAwesomeIcon icon={regular('circle-info')} />,          
    'faCircleNodes': <FontAwesomeIcon icon={regular('circle-nodes')} />,       
    'faCircleUpRight': <FontAwesomeIcon icon={regular('circle-up-right')} />,          
    'faCircleXMark': <FontAwesomeIcon icon={regular('circle-xmark')} />,          
            
    'faClipboard': <FontAwesomeIcon icon={regular('clipboard')} />,   
    'faClockRotateLeft': <FontAwesomeIcon icon={regular('clock-rotate-left')} />,   
    'faClone': <FontAwesomeIcon icon={regular('clone')} />,
    'faCloud': <FontAwesomeIcon icon={regular('cloud')} />,
    'faCloudScale': <FontAwesomeIcon icon={brands('cloudscale')} />,
    'faColumns3': <FontAwesomeIcon icon={regular('columns-3')} />,

    'faDownload': <FontAwesomeIcon icon={regular('download')} />,
    'faEllipsis': <FontAwesomeIcon icon={regular('ellipsis')} />,   
    'faEllipsisVertical': <FontAwesomeIcon icon={regular('ellipsis-stroke-vertical')} />,       
    'faEye': <FontAwesomeIcon icon={regular('eye')} />,
    'faFolder': <FontAwesomeIcon icon={regular('folder')} />,
    'faFolderPlus': <FontAwesomeIcon icon={regular('folder-plus')} />,

    'faFileCertificate': <FontAwesomeIcon icon={regular('file-certificate')} />, 
    'faFileCode': <FontAwesomeIcon icon={regular('file-code')} />, 
    'faFileExcel': <FontAwesomeIcon icon={regular('file-excel')} />, 
    'faFileImport': <FontAwesomeIcon icon={regular('file-import')} />,          
    'faFilterCircleDollar': <FontAwesomeIcon icon={regular('filter-circle-dollar')} />,          
    'faFire': <FontAwesomeIcon icon={regular('fire')} />,          
    'faFirePlace': <FontAwesomeIcon icon={regular('fireplace')} />,          
    
    'faGift': <FontAwesomeIcon icon={regular('gift')} />,          
    'faGiftCard': <FontAwesomeIcon icon={regular('gift-card')} />,          
    'faGlobe': <FontAwesomeIcon icon={regular('globe')} />,      
    'faGoogle': <FontAwesomeIcon icon={brands('google')} />,      
    
    'faHandHoldingHand': <FontAwesomeIcon icon={regular('hand-holding-hand')} />,      
    'faHandShake': <FontAwesomeIcon icon={regular('handshake')} />,      
    'faHourglassClock': <FontAwesomeIcon icon={regular('hourglass-clock')} />,      

    'faIdBadge': <FontAwesomeIcon icon={regular('id-badge')} />,      
    'faImagePortrait': <FontAwesomeIcon icon={regular('image-portrait')} />,      
        
    'faLayerGroup': <FontAwesomeIcon icon={regular('layer-group')} />,
    'faLink': <FontAwesomeIcon icon={regular('link')} />,
    'faListTimeline': <FontAwesomeIcon icon={regular('list-timeline')} />,
    'faLock': <FontAwesomeIcon icon={regular('lock')} />,
    'faLockA': <FontAwesomeIcon icon={regular('lock-a')} />,
    'faLockOpen': <FontAwesomeIcon icon={regular('lock-open')} />,

    'faMagnifyingGlass': <FontAwesomeIcon icon={regular('magnifying-glass')} />,        
    'faMessageBot': <FontAwesomeIcon icon={regular('message-bot')} />,        
    'faMerge': <FontAwesomeIcon icon={regular('merge')} />,        
    'faMicrosoft': <FontAwesomeIcon icon={brands('microsoft')} />,
    
    'faPaperPlane': <FontAwesomeIcon icon={regular('paper-plane')} />,        
    'faPen': <FontAwesomeIcon icon={regular('pen')} />,   
    'faPercent': <FontAwesomeIcon icon={regular('percent')} />,   
    'faPersonDolly': <FontAwesomeIcon icon={regular('person-dolly')} />,   
    'faPlay': <FontAwesomeIcon icon={regular('play')} />,   
    'faPlus': <FontAwesomeIcon icon={regular('plus')} />, 
    
    'faRadar': <FontAwesomeIcon icon={regular('radar')} />,
    'faRobot': <FontAwesomeIcon icon={regular('robot')} />,
    'faRocketLaunch': <FontAwesomeIcon icon={regular('rocket-launch')} />,
    'faRotate': <FontAwesomeIcon icon={regular('rotate')} />,        
    
    'faRetweet': <FontAwesomeIcon icon={regular('retweet')} />,

    'faScrewdriverWrench': <FontAwesomeIcon icon={regular('screwdriver-wrench')} />,      
    'faShieldKeyhole': <FontAwesomeIcon icon={regular('shield-keyhole')} />,            
    'faSkull': <FontAwesomeIcon icon={regular('skull')} />,        
    'faStar': <FontAwesomeIcon icon={regular('star')} />,        
    
    'faTags': <FontAwesomeIcon icon={regular('tags')} />,          
    'faTicket': <FontAwesomeIcon icon={regular('ticket')} />,          
    'faTrashCan': <FontAwesomeIcon icon={regular('trash-can')} />,          

    'faUser': <FontAwesomeIcon icon={regular('user')} />,          
    'faUsers': <FontAwesomeIcon icon={regular('users')} />,          

    'faVault': <FontAwesomeIcon icon={regular('vault')} />,          
    'faWebHook': <FontAwesomeIcon icon={regular('webhook')} />,          

    'faXmark': <FontAwesomeIcon icon={regular('xmark')} />,          
  }
});

render(  
  <BrowserRouter basename={process.env.PUBLIC_URL} >
    <App />
  </BrowserRouter>  
, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();