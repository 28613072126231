import { ISdkCustomerDatasource, ISdkCustomerDatasourceCreate } from "./models/ISdkCustomerDatasource";
import { ISdkObjectTemplate } from "./models/ISdkObjectTemplate";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkCustomerDatasourceClient {

    private sdkClient: SdkClient<any>    
    private sdkTemplatesClient: SdkClient<ISdkObjectTemplate>

    constructor(tenantId: string, customerId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<any>('/api/v1/tenants/' + tenantId + '/svp/customers/' + customerId + '/datasources', token);
        this.sdkTemplatesClient = SdkClientServiceDiscoveryService.Discover<ISdkObjectTemplate>('/api/v1/tenants/' + tenantId + '/svp/customers/' + customerId + '/datasources/templates', token);
    }

    listDatasources(): Promise<ISdkCustomerDatasource[]> 
    {                
        return this.sdkClient.list('/');                
    }

    listDatasourceTemplates(): Promise<ISdkObjectTemplate[]> 
    {        
        return this.sdkTemplatesClient.list('');            
    }

    getDatasource(id: string): Promise<ISdkCustomerDatasource> 
    {                
        return this.sdkClient.get('/' + id);                
    }

    createDatasource(datasource: ISdkCustomerDatasourceCreate): Promise<ISdkCustomerDatasource> 
    {            
        const payload = {
            name: datasource.name,
            type: datasource.type,
            comment:datasource.comment,
            fieldValues: datasource.fieldValues
        }

        return this.sdkClient.post('/', payload).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })          
    }

    updatDatasource(id: string, datasource: ISdkCustomerDatasourceCreate): Promise<ISdkCustomerDatasource> 
    {                
        const payload = {
            name: datasource.name,
            type: datasource.type,
            comment:datasource.comment,
            fieldValues: datasource.fieldValues
        }

        return this.sdkClient.put('/' + id, payload).then(async () => {
            return this.getDatasource(id)
        })               
    }

    deleteDatasource(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }

    syncDatasource(id: string, period: string, cleanUpData: boolean): Promise<Boolean> 
    {
        return this.sdkClient.postToGetStatus('/' + id + '/periods/' + period + '/sync', { cleanUpData: cleanUpData});
    }

    aggregateDatasource(id: string, period: string): Promise<Boolean> 
    {
        return this.sdkClient.postToGetStatus('/' + id + '/periods/' + period + '/aggregate', {});
    }
}