import { useParams } from "react-router-dom"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { ISdkRole } from "../lib/sdk/models/ISdkRole"
import { IColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { useBoolean } from "@fluentui/react-hooks"
import { PanelType } from "@fluentui/react"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { SdkTenantRolePermissionClient } from "../lib/sdk/SdkTenantRolePermissionClient"
import { ISdkRolePermission } from "../lib/sdk/models/ISdkRolePermission"

export interface ITenantRoleManagementPermissionsProps extends IAppWindowBaseProps {
    role?: ISdkRole
}

export const TenantRoleManagementPermissions = (props: ITenantRoleManagementPermissionsProps) => {
    
    // get the current tenant state
    const { tenantId } = useParams()         

    // get the authenticaiton 
    const auth = useAuthInContext(tenantId as string)

    const [isRefreshRequest, ] = useBoolean(false)    
            
    const buildColumns = (builder: IColumnsBuilder<ISdkRolePermission>): void  => 
    {                
        builder.TextColumn({name: "Permission", value: 'displayName', maxWidth: 250})                                                        
        builder.TextColumn({name: "Identifier", value: 'permissionIdentifier', maxWidth: 250})                                                        
    }

    const reloadData = async (): Promise<ISdkRolePermission[]> => {

        if (!props.role)
            return []

        const client = new SdkTenantRolePermissionClient(tenantId as string, props.role?.roleIdentifier, auth.currentToken as string)
        const permissions = await client.loadPermissions()
        return permissions;
    }

    const dissmissDialog = () => {     
        if (props.dismissDialog)
            props.dismissDialog()
    }
        
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            type={PanelType.large}
            headerText={'Permissions for role ' + props.role?.roleIdentifier}
            onDismiss={dissmissDialog}                                                
            progessMessage={''}
            submitLabel={'Close'}
            dissmissLabel={''}                        
            noDissmissButton={true}
            isValid={true}
            onSubmit={() => Promise.resolve()}>
                
            <NavigationalDetailsListWithStandardOperations<ISdkRolePermission> 
                uniqueIdentifier='permissionsroot' 
                onBuildColumns={buildColumns} 
                onLoadData={reloadData} 
                hideReferesh={true}
                refresh={isRefreshRequest}
                disabledItemSelect={true}
                allowSorting={true} />                                          
        </PanelWithDefaultFooter>            
    ) 
}

