import { ISdkWebHookRegistration } from "./models/ISdkWebHookRegistration";
import { ISdkWebHookRegistrationCreate } from "./models/ISdkWebHookRegistrationCreate";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkWebhookClient {
    
    private sdkClient: SdkClient<ISdkWebHookRegistration>

    constructor(
        private _tenantId: string, 
        private _token: string)        
    {
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkWebHookRegistration>('/api/v1/tenants/' + _tenantId + '/webhooks', _token);                       
    }

    list(): Promise<ISdkWebHookRegistration[]> {
        return this.sdkClient.list('');                
    }

    get(id: string): Promise<ISdkWebHookRegistration>
    {
        return this.sdkClient.get('/' + id + '/details')
    }

    create(createRequest: ISdkWebHookRegistrationCreate): Promise<ISdkWebHookRegistration> 
    {                    
        return this.sdkClient.post('', createRequest).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })               
    }

    update(id: string, createRequest: ISdkWebHookRegistrationCreate): Promise<ISdkWebHookRegistration> 
    {                    
        return this.sdkClient.put('/' + id, createRequest).then(async () => {
            return this.get(id)
        })               
    }

    delete(id: string): Promise<Boolean> {
        return this.sdkClient.delete('/' + id)
    }
    
}