import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { Label, PanelType, Stack } from "@fluentui/react"
import { ControlledTextField } from "../lib/forms/ControlledTextField"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { UnControlledListFormsContainer } from "../lib/forms/UnControlledListFormsContainer"
import { FluentProvider, makeStyles } from "@fluentui/react-components"
import { ControlledFilterItemList } from "../lib/forms/ControlledFilterItemList"
import { IFilterItem } from "../lib/forms/UnControlledFilterItem"
import { SdkWebhookClient } from "../lib/sdk/SdkWebhookClient"
import { ISdkWebHookRegistrationCreate } from "../lib/sdk/models/ISdkWebHookRegistrationCreate"
import { ISdkWebHookRegistration } from "../lib/sdk/models/ISdkWebHookRegistration"
import { useAppShellContext } from "../lib/ds/components/AppShell"

export interface IAddOrEditWebhookRegistrationProps extends IAppWindowBaseProps {   
    tenantId: string  
    item?: ISdkWebHookRegistration  
}

type WebHookHeader = {
    header: string,
    value: string
}

type WebhookRegistrationData = {
    name: string,
    uri: string,    
    selectorRules: IFilterItem[]        
};

const makeCustomHeaderDataEditorStyle = makeStyles({
    root: {        
        display: 'flex',
        flexDirection: 'row',                
        width: '100%',        
        columnGap: '10px'
    },
    key: {
        width: '100%'
    },
    type: {
        minWidth: '180px'
    }            
});


export const AddOrEditWebhookRegistration = (props: IAddOrEditWebhookRegistrationProps) => {
        
    const auth = useAuthInContext(props.tenantId as string)            
    const appShellContext = useAppShellContext()

    const [isProcessing, setIsProcssing] = useState<boolean>(false)   
    
    const [headers, setHeaders] = useState<WebHookHeader[]>([])
    const [headersChanged, setHeadersChanged] = useState<boolean>(false)
    
    const availableFields : {key: string, text: string}[] = [
        { key: 'EmitterId', text: 'EmitterId' },
        { key: 'SubjectId', text: 'SubjectId' },
        { key: 'SubjectType', text: 'SubjectType' },
        { key: 'EventCode', text: 'EventCode' }        
    ]
    
    const emptyDefaults: WebhookRegistrationData = {
        name: '',
        uri: '',        
        selectorRules: []
    }

    const convertItemToDefaults = (item: ISdkWebHookRegistration): WebhookRegistrationData => {
        
        return {
            name: item.name,
            uri: item.requestUrl,            
            selectorRules: item.selectorRules.map(f => {
                return {
                    key: f.field,
                    operator: f.operator,
                    field: f.field,
                    value: f.value,
                    condition: f.condition,
                    compare: f.compare === 'CaseInSensitive'
                }
            })
        }        
    }
    
    const { handleSubmit, control, reset, formState } = useForm<WebhookRegistrationData>({
        defaultValues: emptyDefaults,            
        reValidateMode: "onSubmit",
        mode: "all"
    });

    useEffect(() => {
        if (!props.item) { return }        
        reset(convertItemToDefaults(props.item))

        var headers: WebHookHeader[] = []
        Object.keys(props.item.requestHeaders).forEach(k => {
            headers.push({ header: k, value: props.item!.requestHeaders[k] })
        })
        setHeaders(headers)
    // eslint-disable-next-line    
    }, [props.item])


    const dissmissDialog = () => {
        setIsProcssing(false)     
        setHeaders([])
        setHeadersChanged(false)  
        reset(emptyDefaults) 
        if (props.dismissDialog)
            props.dismissDialog()
    }


    const onSubmit = (): Promise<string | void> => {

        // handle the submission of the base form 
        const submitHandler = handleSubmit((data: WebhookRegistrationData) => {
                                    
            const createRequest: ISdkWebHookRegistrationCreate = {
                name: data.name,
                requestUrl: data.uri,
                requestHeaders: headers.reduce((acc, h) => {
                    acc[h.header] = h.value
                    return acc
                }, {} as any),
                selectorRules: data.selectorRules ? data.selectorRules.map(f => {
                    return {                        
                        operator: f.operator,
                        field: f.field,
                        value: f.value,
                        condition: f.condition,
                        compare: f.compare ? 'CaseInSensitive' : 'CaseSensitive'
                    }
                }) : []
            }

            // at this point we can create a new item and close the dialog
            const sdkClient = new SdkWebhookClient(props.tenantId, auth.currentToken as string)

            if (props.item) {
                return sdkClient.update(props.item.id, createRequest)            
            } else {
                return sdkClient.create(createRequest)            
            }                                   
        })
        
        return submitHandler()
        .then(() => { dissmissDialog() })
        .finally(() => { setIsProcssing(false)})           
    }

    const onAddHeader = (header: WebHookHeader) => {        
        setHeaders(prevHeaders => [...prevHeaders, header])
        setHeadersChanged(true)
        return Promise.resolve()
    }

    const onDeleteHeader = (header: WebHookHeader) => {
        setHeaders(prevHeaders => prevHeaders.filter(p => p.header !== header.header))
        setHeadersChanged(true)
        return Promise.resolve()
    }

    const onUpdateHeader = (item: WebHookHeader) => {
        setHeaders(prevHeaders => [...prevHeaders.map(p => p.header === item.header ? item : p)])
        setHeadersChanged(true)
        return Promise.resolve()
    }

    const editorStyles = makeCustomHeaderDataEditorStyle()
    
    return (       
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={props.item ? 'Update webhook registration' : 'Add new webhook registration'}
            onDismiss={dissmissDialog}                                    
            type={PanelType.large}
            progessMessage={(props.item ? 'Updating existing' : 'Creating new') + ' webhook registration'}
            submitLabel={props.item ? 'Update' : 'Create'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid && (formState.isDirty || headersChanged)}
            onSubmit={onSubmit}
            errorMessagePrefix= {props.item? 'Error updating the webhook registration!' : 'Error creating the webhook registration!'}>
                
                <FluentProvider theme={appShellContext.themeV9}>
                 <div>
                    <div style={{marginBottom: '10px'}}>
                        <p>Webhooks are used to notify external systems instantly about changes in the solution. All auditable events can be reflected in a webhook as well:</p> 
                    </div>

                    <div>
                        <div>
                            <Stack tokens={{ childrenGap: 15 }}>                
                                <ControlledTextField label={'Name'} control={control} name={'name'} rules={{ required: 'a valid valud is required'}} disabled={isProcessing} />                                                                
                                <ControlledTextField label={'Request Url'} control={control} name={'uri'} rules={{ required: 'a valid valud is required'}} disabled={isProcessing} />                                                                
                                <Label>Request Headers (will be injected in the call)</Label>                             
                                <UnControlledListFormsContainer disabled={false} items={headers} itemDefaultValues={{header: '', value: ''}} onAddItem={onAddHeader} onDeleteItem={onDeleteHeader} onUpdateItem={onUpdateHeader} renderEditor={(controlEditor) => (
                                    <div className={editorStyles.root}>                            
                                        <ControlledTextField className={editorStyles.key} key={'name'} control={controlEditor} name={'header'} placeholder={'Name of the header...'} disabled={false} />                                                    
                                        <ControlledTextField className={editorStyles.key} key={'name'} control={controlEditor} name={'value'} placeholder={'Value of the header...'} disabled={false} />                                                                                            
                                    </div>            
                                )} />            
                            </Stack>  
                            <div>
                                <Label style={{marginTop: '10px'}}>Trigger Webhook rules (empty means webhook becomes triggered with every audit event)</Label>
                                <ControlledFilterItemList control={control} name={'selectorRules'} availableFields={availableFields} disabled={isProcessing} />                            
                            </div>                                                   
                        </div>
                    </div>                                                
                </div>
                </FluentProvider>                

        </PanelWithDefaultFooter>
    )
}