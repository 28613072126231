import { ISdkRolePermission } from "./models/ISdkRolePermission";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkTenantRolePermissionClient {
    private _sdkPermissionClient: SdkClient<ISdkRolePermission>

    constructor(
        private _tenantId: string,
        private _roleIdentifier: string,
        private _token: string)         
    {        
        this._sdkPermissionClient = SdkClientServiceDiscoveryService.Discover<ISdkRolePermission>('/api/v1/tenants/' + this._tenantId + '/roles/' + this._roleIdentifier + '/permissions', this._token);
    }

    loadPermissions(): Promise<ISdkRolePermission[]> {
        return this._sdkPermissionClient.list('');
    }
}