import { ISdkCustomerGroup } from "./models/ISdkCustomerGroup";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkCustomerGroupClient {

    private sdkClient: SdkClient<ISdkCustomerGroup>
        
    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkCustomerGroup>('/api/v1/tenants/' + tenantId + '/svp/groups', token);
    }    
    
    listCustomerGroups(parent?: string, recursive?: boolean): Promise<ISdkCustomerGroup[]> 
    {       
        const parentGroupId = parent ? parent : 'root' 
        return this.sdkClient.list('/' + parentGroupId + '/childs' + (recursive ? '?recursive=true' : ''));                
    }

    createCustomerGroup(name: string, groupType: 'standard' | 'partnerchannel', parent?: string): Promise<ISdkCustomerGroup> 
    {
        const parentGroupId = parent ? parent : 'root'

        const payload = {
            name: name,
            groupType: groupType
        }

        return this.sdkClient.post('/' + parentGroupId + '/childs', payload).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })        
    }

    updateCustomerGroup(groupId: string, name: string): Promise<ISdkCustomerGroup>
    {
        return this.sdkClient.put('/' + groupId + '/details', { name: name }).then(async () => {
            return this.sdkClient.get('/' + groupId + '/details');
        })
    }

    getCustomerGroup(id: string): Promise<ISdkCustomerGroup> 
    {
        const groupId = id ? id : 'root'         
        return this.sdkClient.get('/' + groupId + '/details');                        
    }

    deleteCustomerGroup(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }

    moveCustomerGroup(id: string, newParentGroupId: string): Promise<Boolean>
    {
        return this.sdkClient.postToGetStatus('/' + id + '/move', { targetParentId: newParentGroupId});
    }
}