import { PanelType } from "@fluentui/react"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { SdkCustomerGroupTree } from "../lib/sdkdatacomponents/SdkCustomerGroupTree"
import { useState } from "react"
import { ICustomerManagementItem, ServiceProviderCustomerManagementUIService } from "./ServiceProviderCustomerManagementUIService"
import { SdkCustomerGroupClient } from "../lib/sdk/SdkCustomerGroupClient"

export interface IMoveResourceDialogProps extends IAppWindowBaseProps {
    tenantId: string
    sourceParentGroupId: string
    itemstoMove: ICustomerManagementItem[]
}

export const MoveResourceDialog = (props: IMoveResourceDialogProps) => { 
    
    const auth = useAuthInContext(props.tenantId as string)
    const uiService = new ServiceProviderCustomerManagementUIService(props.tenantId, auth.currentToken as string)

    const [selectedGroup, setSelectedGroup] = useState<string>()
    const [doubleConfirmMessage, setDoubleConfirmMessage] = useState<string | undefined>()

    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onExecuteMoveOperation = async () => {

        if (!selectedGroup) { return Promise.reject() }

        // get the list of customers to move
        const customersToMove = props.itemstoMove.filter(r => r.type === 'customer')

        // get the list of groups to move
        const groupsToMove = props.itemstoMove.filter(r => r.type === 'group')

        // move customers
        const customerClient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
        await Promise.all<Boolean>(customersToMove.map(r => customerClient.moveCustomer(r.id, selectedGroup)))

        // move groups 
        const groupClient = new SdkCustomerGroupClient(props.tenantId, auth.currentToken as string)
        await Promise.all<Boolean>(groupsToMove.map(r => groupClient.moveCustomerGroup(r.id, selectedGroup)))
    }

    const onSelectedGroupChanged = (groupId: string) => {

        // reset the confirm message
        setDoubleConfirmMessage(undefined)

        // try to get the partner channel for the target group
        uiService.loadPartnerChannelItem(groupId).then(partnerChannelTargetGroup => {
            
            // try to get the parter channel for the source group
            return uiService.loadPartnerChannelItem(props.sourceParentGroupId).then(partnerChannelSourceGroup => {

                // check of we move into or from a partner channel
                let isMoveWithPartnerChannel = false
                if (!partnerChannelSourceGroup && partnerChannelTargetGroup) { isMoveWithPartnerChannel = true}
                if (partnerChannelSourceGroup && !partnerChannelTargetGroup) { isMoveWithPartnerChannel = true}
                if (partnerChannelSourceGroup && partnerChannelTargetGroup && partnerChannelSourceGroup.id !== partnerChannelTargetGroup.id) { isMoveWithPartnerChannel = true}
                
                // check if both groups are different
                if (isMoveWithPartnerChannel) {
                    setDoubleConfirmMessage('You are about to move resources into a partner channel or away from a partner channel. Please be aware that the corresponding reseller will get access or is loosing acces to the customer details!')
                }
                
                // set the group
                setSelectedGroup(groupId)
            })
        })                
    }

    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Move ' + props.itemstoMove.length + ' resource' + (props.itemstoMove.length > 1 ? 's' : '')}
            onDismiss={dissmissDialog}                                    
            type={PanelType.medium}
            progessMessage={'Moving resources...'}
            submitLabel={'Move'}
            dissmissLabel={'Cancel'}                        
            isValid={selectedGroup !== undefined}            
            onSubmit={onExecuteMoveOperation}
            doubleConfirmMessage={doubleConfirmMessage}
            >   

            <div>
                <p>Please kindly choose a group where you would like the selected resources to be relocated to:</p>            
                <SdkCustomerGroupTree tenantId={props.tenantId} virutalRootId={'root'}  onChange={onSelectedGroupChanged}/>                                   
            </div>            
        </PanelWithDefaultFooter>
    )
}
