import { useParams } from "react-router-dom"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { ISdkRole } from "../lib/sdk/models/ISdkRole"
import { IColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { SdkTenantRoleClient } from "../lib/sdk/SdkTenantRoleClient"
import { useBoolean } from "@fluentui/react-hooks"
import { useState } from "react"
import { TenantRoleManagementPermissions } from "./TenantRoleManagementPermissions"

export interface ITenantRoleManagementProps {
}

export const TenantRoleManagement = (props: ITenantRoleManagementProps) => {
    
    // get the current tenant state
    const { tenantId } = useParams()         

    // get the authenticaiton 
    const auth = useAuthInContext(tenantId as string)

    const [isRefreshRequest, ] = useBoolean(false)    
    const [clickedItem, setClickedItem] = useState<ISdkRole>();

    const columnClickOperation = (item: ISdkRole) => {
        setClickedItem(item)
    }

    const buildColumns = (builder: IColumnsBuilder<ISdkRole>): void  => 
    {                
        builder.TextColumn({name: "Role", value: 'roleIdentifier', maxWidth: 250, onClick: columnClickOperation})                                                        
    }

    const reloadData = async (): Promise<ISdkRole[]> => {
        const client = new SdkTenantRoleClient(tenantId as string, auth.currentToken as string)
        const roles = await client.loadRoles()
        return roles;
    }
        
    return (
        <div className="mv-content-container">

            <PageHeader title={'Role Management'}>            
                <span>Get an overview about all existing roles and the corresponding permissions in the system </span>
            </PageHeader>      

            <NavigationalDetailsListWithStandardOperations<ISdkRole> 
                uniqueIdentifier='roleroot' 
                onBuildColumns={buildColumns} 
                onLoadData={reloadData} 
                refresh={isRefreshRequest}
                disabledItemSelect={true}
                allowSorting={true} />       
            <TenantRoleManagementPermissions isVisible={!!clickedItem} role={clickedItem} dismissDialog={() => { setClickedItem(undefined)}}/>
        </div>
    ) 
}

